:focus {
  outline: 0;
}

.login_input_text{
  position: absolute;
  top: 0;
  left: 0;
  line-height: 147.6%;
  transition: top .3s cubic-bezier(.49,.35,.38,.91);
  font-size: 16px;
  cursor: text;
}

.login_input{
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  padding-bottom: 8px;
  font-size: 18px;
  color: #000;
  border-bottom: 1px solid #ADB6C5;
}

.login_input:focus{
  border-bottom-color: #2fd25a;
}

/* Email or pass error */
.login_input_error{
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  padding-bottom: 8px;
  font-size: 16px;
  font-family: regular;
  border-bottom-color: #FF1973
}

.login_input::-ms-reveal,::-ms-clear{/*Delete eye in Edge chromiun*/
  display: none;
}
.login_input_error::-ms-reveal,::-ms-clear{/*Delete eye in Edge chromiun*/
  display: none;
}

/* Disabled boxshadow red on inputs */
.login_input:-moz-ui-invalid{
  box-shadow: 0 0 1.5px 1px transparent;
}
.login_input_error:-moz-ui-invalid{
  box-shadow: 0 0 1.5px 1px transparent;
}

.login_input:focus + .login_input_text,
.login_input:invalid + .login_input_text,
.login_input_error:focus + .login_input_text,
.login_input_error:invalid + .login_input_text{
  top: -20px;
  font-size: 14px;
  color: #6b6e75;
  outline: 0;
  user-select: none;
}

/* --------------------- animations --------------------- */

.upee_image_animation{
  /* animation: upee_image 10s linear both; */
  animation: upee_image;
  /* animation-duration: 0.1s; */
}

@keyframes upee_image{
  0%{
    transform:translateX(1000px);
    /* opacity:0; */
  }
  100%{
    transform:translateX(0px);
    /* opacity:1; */
  }
}

.upee_image_what_is_upee_animation{
  animation: upee_image_what_is_upee;
}

@keyframes upee_image_what_is_upee{
  0%{
    transform:translateX(-1000px);
    /* opacity:0; */
  }
  100%{
    transform:translateX(0px);
    /* opacity:1; */
  }
}

.text1_what_is_upee_animation{
  animation: text1_what_is_upee;
}

@keyframes text1_what_is_upee{
  0%{
    transform:translateX(1000px);
  }
  100%{
    transform:translateX(0px);
  }
}

.upee_take_care_of_you{
  animation: upee_take_care_of_you_animation;
}
@keyframes upee_take_care_of_you_animation{
  0%{
    transform:translateY(1000px);
  }
  100%{
    transform:translateY(0px);
  }
}

.banner_back {
  animation: banner_back_animation;
}
.banner_back_out {
  animation: banner_back_out_animation;
}

@keyframes banner_back_animation{
  0%{
    /* transform:translateX(1000px); */
    opacity: 0;
  }
  100%{
    /* transform:translateX(0px); */
    opacity: 1;
  }
}

@keyframes banner_back_out_animation{
  0%{
    transform:translateX(0px);
  }
  100%{
    transform:translateX(-1000px);
  }
}